*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
::-webkit-scrollbar{
  display: none !important;
}
body::-webkit-scrollbar{
  display: none !important;
}
html::-webkit-scrollbar{
  display: none !important;
}