.kyc-otp-page-section_{
    width: 100%;
    margin-top: 20px;
}
.kyc-otp-form_{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
   
}
.kyc-otp-form-group_{
    width: 100%;
}
.kyc-otp-form-input-label_{
    font-size: 15px;
    color: #ededed;
    text-transform: capitalize;
    margin-bottom: 10px;
    text-align: center;
}
.otp-input-box_{
    width: 100%;
    display: flex;
    justify-content: center;
}
.kyc-otp-form-actions_{
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
}
.kcy-otp-form-action-button_{
    width: 100px;
    height: 38px;
    border: none;
    background: linear-gradient(#fbb700, #151515);
    border-radius: 6px;
    color: #fff;
    text-transform: uppercase;
    margin-top: 15px;
    font-weight: bold;
}
.kcy-otp-form-action-button_.disabled{
     background: linear-gradient(#fbb700, #151515);
}
.after-kyc-popup-box_{
    width: 90%;
    height: 160px;
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%,-30%);
    z-index: 9999;
    border-radius: 6px;
}
.after-kyc-popup-box-container_{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.after-kyc-success-message_{
    font-size: 22px;
    font-weight: 600;
    color: rgb(1, 168, 1);
}
.after-kyc-popup-box-action-btn_{
    width: 120px;
    height: 40px;
    border: none;
    background-color: #000000;
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    margin-top: 20px;
    border-radius: 6px;
    transition: transform 0.5s ease-in-out;
}
.after-kyc-popup-box-action-btn_:active{
    transform: scale(0.8);
}