.utr-deposit-page_ {
    width: 100%;
    padding-top: 10px;
    background-color: #000;
    min-height: 100vh;
}

.utr-deposit-page_.FB {
    background-color: #fff;
}

.utr-deposit-page-container_ {
    width: 100%;
}

.utr-deposit-page-header-container_ {
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 9998;
    background-color: #000;
    padding-bottom: 30px;
    padding-top: 10px;
}

.utr-deposit-page-header-container_.FB {
    background-color: #fff;
}

.utr-loader_ {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.utr-w-toast {
    background-color: transparent !important;
}

.error-class {
    background: #4a0500 !important;
    color: #fff !important;
}

.success-class {
    background-color: green !important;
    color: #fff !important;
}

.utr-loader-icon {
    color: #fff !important;
    font-size: 19px !important;
}

.utr-deposit-page-heading_ {
    width: 100%;
    display: flex;
    justify-content: center;
}

.label_ {
    font-size: 17px;
    color: #fff;
    margin-left: 10px;
}

.automatic-heading_ {
    font-size: 17px;
    text-transform: capitalize;
    color: #fff;
}

.automatic-heading_.FB {
    color: #000;
}

.radio-group {
    display: flex;
    align-items: center;
}

.cr-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    gap: 10px;
    display: flex;
}

.cr-wrapper input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.cr-input {
    position: relative;
    width: 24px;
    height: 24px;
    background: #000;
    border: 1px solid #F1F5F9;
    border-radius: 50%;
    transition: background 250ms, border-color 250ms;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cr-input::after {
    content: "";
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    border-radius: 50%;
    display: none;
    transition: background 250ms;
}

.cr-wrapper input:checked~.cr-input {
    background: #000;
    border-color: #000;
    border: 1px solid #fff;
}

.cr-wrapper input:checked~.cr-input::after {
    display: block;
}

/* Pay now input groups ------------------------- */
.amount-and-employee-code-form_ {
    width: 98%;
    margin: auto;

}

.pay-now-input-group_ {
    width: 95%;
    margin: auto;
    margin-top: 15px;
}

.pay-now-label_ {
    font-size: 16px;
    color: #fff;
    margin-bottom: 5px;
    font-weight: bold;
}

.pay-now-label_.FB {
    color: #333333;
}

.pay-now-deposit-input_ {
    width: 100%;
    height: 40px;
    border: none;
    color: #fff;
    outline: none;
    font-size: 14px;
    padding-left: 12px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #171717;
    border: 1px solid #393939;
}

.pay-now-deposit-input_.FB {
    background-color: transparent;
    border: 1px solid #182173;
    color: #000;
}

.pay-now-deposit-input_::placeholder {
    color: #ccc;
    font-size: 14px;
}

.pay-now-deposit-input_.FB::placeholder {
    color: #000;
    font-size: 14px;
}

.deposit-form-actions_ {
    width: 150px;
    margin: auto;
    margin-top: 20px;
}

.pay-now-button_ {
    width: 100%;
    height: 43px;
    border: 2px solid #435b87;
    background-color: transparent;
    color: #1fb182;
    font-size: 18px;
    font-weight: 700;
    border-radius: 4px;
    text-transform: uppercase;
    border-radius: 24px;
    transition: transform 0.5s ease-in-out;
}
.pay-now-button_:active{
    transform: scale(0.8);
}

/* Payment accounts container-------------------------- */
.payment-accounts-page_ {
    width: 100%;
}

.payment-accounts-container_ {
    width: 100%;
}

.no-payment-accounts-message_ {
    font-size: 13px;
    color: #fff;
    text-align: center;
    padding-top: 100px;
}

.no-payment-accounts-message_.FB {
    color: #333333;
}

/* Payment methods--------------------------- */
.payment-accounts-box_ {
    width: 90%;
    margin: auto;
    background: #222;
    padding: 14px 10px 14px 10px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.payment-accounts-category_ {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
}

.payment-accounts-list_ {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.payment-accounts-banner_ {
    width: 90%;
    margin: auto;
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.payment-account_ {
    width: 60px;
    height: 60px;
    margin: 0px 15px;
}

.payment-account-img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.form-loader_ {
    color: #fff !important;
}