.popup-box-container_ {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    position: fixed;
    top: 0px;
    left: 0px;

}

.upi-popup-box_ {
    width: 95%;
    margin: auto;
    margin-top: 100px;
    height: 200px;
    background-color: #fff;
    border-radius: 12px;
    z-index: 9999;
    padding: 20px 0px;
    position: relative;
    border: 2px solid #2e6add;

}
.close-popup-icon_{
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 25px !important;
    color: #e91100 !important;
}

.upi-popup-box-container_ {
    width: 100%;
    height: 100%;

}

.upi-input-form_ {
    width: 90%;
    margin: auto;
    margin-top: 30px;
}

.upi-input-group_ {
    width: 100%;
    height: 100%;

}

.upi-input-group-input_ {
    width: 100%;
    height: 40px;
    border-radius: 12px;
    outline: none;
    border: 2px solid #2e6add;
    font-size: 15px;
    padding-left: 12px;
    box-sizing: border-box;
}

.upi-input-group-input_::placeholder {
    font-size: 15px;
    color: #000000;
}

.upi-input-form-actions_ {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.upi-input-form-action_ {
    width: 48%;
    height: 40px;
}

.upi-input-form-action-btn_ {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    border: 2px solid #2e6add;
    background-color: transparent;
    border-radius:12px;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 700;
    color: #000000;
}