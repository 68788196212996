
.not-found-page_{
    width: 100%;
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.not-found-content_{
    width: 100%;
    text-align: center;
}
.not-found-message_{
    font-size: 17px;
    color: #000000;
    text-transform: capitalize;
}