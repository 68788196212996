.withdrawal-page-section_ {
    width: 100%;
    min-height: 100vh;
    background-color: #000000;
    padding-bottom: 20px;
    padding-top: 8px;
    position: relative;
}
.withdraw-page-loader-container_{
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0px;
    top: 0px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
.withdrawal-page-section-container_ {
    width: 100%;
    margin: auto;
}
.withdrawal-instruction-box_{
    width: 95%;
    margin: auto;
}
.withdrawal-instruction-box-header_{
    width: 100%;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fbb600;
    color: #fff;
    text-transform: uppercase;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.withdrawal-instructions-list_{
    width: 100%;
    padding: 5px 10px 15px 10px;
    background-color: #171717;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.withdrawal-instruction-li_{
    font-size: 13px;
    color: #d7d7d7;
    line-height: 18px;
    margin-top: 3px;

}
.withdrawal-page-section-header_ {
    width: 100%;
    padding: 10px 0px;
    border-top: 1px solid #898989;
    border-bottom: 1px solid #898989;
    margin-top: 0px;
}

.withdrawal-details-heading_{
    width: 95%;
    margin: auto;
    font-size: 17px;
    color: #fff;
    text-transform: capitalize;
    text-align: start;
}

.withdrawal-details-form_ {
    width: 95%;
    margin: auto;
    margin-top: 10px;
}

.withdrawal-details-form-group_ {
    width: 100%;
    margin-bottom: 13px;
}
.withdrawal-details-form-group_.upiid-and-scanner_{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.upiid-form-group_{
    width: 50%;
}
.or-divider_{
    color: #fbb700;
    padding: 0px 10px 0px 10px;
}
/* File input container */
.scanner-input-group_ {
    width: 40%;
    position: relative;
    overflow: hidden;
    display: inline-block;
    border: 1px dashed #ccc;
    border-radius: 4px;
    padding: 8px;
    text-align: center;
  }

  .disabled-file-choose_{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #ededed38;
    cursor: not-allowed;
    border: none;
  }
  
  
  /* Actual file input - hidden visually but still functional */
  .scanner-input-field_ {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .scanner-upload-label_{
    font-size: 14px;
    color: #898989;
    text-transform: capitalize;
    margin-bottom: 3px;
   text-align: center;
    display: flex;
    align-items: center;
  }
  /* Custom styled button/label */
  .scanner-input-group_::after {
    width: 80%;
    content: 'Choose File';
    display: inline-block;
    padding: 5px 16px;
    background-color: #171717;
    border-radius: 4px;
    color: #898989;
    font-size: 14px;
  }
  
  /* Hover effect */
  .scanner-input-group_:hover::after {
    background-color: #171717;
  }
  
  /* Show selected file name */
  .scanner-input-group_::before {
    content: attr(data-file);
    display: block;
    margin-bottom: 8px;
    font-size: 12px;
    color: #fff;
  }
  

.withdrawal-details-form-group-label_ {
    font-size: 14px;
    color: #898989;
    text-transform: capitalize;
    margin-bottom: 5px;
    margin-left: 3px;
    display: flex;
    align-items: center;
}
.close-file-icon_{
    font-size: 25px !important;
    color: #b81004 !important;
    margin-left: 6px;
  
}


.withdrawal-details-form-group-input_ {
    width: 100%;
    height: 42px;
    border-radius: 8px;
    outline: none;
    border: none;
    background: #171717;
    color: #fff;
    padding-left: 12px;
    font-size: 15.5px;
}
.withdrawal-details-form-group-input_.disabled{
    background-color: #ededed38;
}
.withdrawal-details-form-group-input_::-webkit-inner-spin-button{
    display: none;
}
.withdrawal-details-form-group-input_::-webkit-outer-spin-button{
    display: none;
}


.form-error_ {
    font-size: 11px;
    margin-top: 2px;
    color: #a40f05;
    margin-left: 4px;
}

.withdrawal-note_ {
    width: 100%;
    padding: 7px 10px;
    background-color: #35353f;
    border-radius: 8px;

}

.withdrawal-note-text_ {
    color: #ededed;
    font-size: 12px;
}

.withdrawal-details-form-actions_ {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
}

.withdrawal-details-form-action-btn_ {
    width: 120px;
    height: 42px;
    border: none;
    background: linear-gradient(#fbb700, #151515);
    border-radius: 6px;
    color: #fff;
    text-transform: uppercase;
}